.burger-menu,
.burger-menu.open {
  width: 60px;
  display: block;
  cursor: pointer;
  /* position: fixed; */
  margin-top: 55px;
  z-index: 9999;
  padding-left: 12px;
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 2px;
  background-color: rgb(255, 255, 255);
  margin: 5px 0;
  transition: 0.4s;
}

.burger-menu .bar1_projects,
.bar2_projects,
.bar3_projects {
  width: 25px;
  height: 2px;
  background-color: rgb(0, 0, 0);
  margin: 5px 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

.burger-menu.open .bar1_projects {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
  background-color: rgb(255, 255, 255);
}

.burger-menu.open .bar2_projects {
  opacity: 0;
}

.burger-menu.open .bar3_projects {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
  background-color: rgb(255, 255, 255);
}

.text_box {
  display: flex;
}

/* Menu */

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0px;
}

.block {
  display: block;
}
