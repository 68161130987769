.header {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.header_projects {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.571);
  z-index: 10000;
}

/*Logo////////////////////////////*/

.header__logo_box {
  margin-top: 50px;
  margin-right: 10px;
  margin-left: 20px;
}
.header__logo {
  width: 40px;
  height: 40px;
  background-image: url(../../assets/img/white_2.png);
  background-size: contain;
}
.header__logo_projects {
  width: 50px;
  height: 50px;
  background-image: url(../../assets/img/black_3.png);
  background-size: contain;
}

/*TITLE////////////////////////////*/

.header__textbox {
  display: block;
  margin: auto;
  padding-top: 15px;
  text-align: center;
  cursor: pointer;
}

.heading_primary {
  color: rgb(255, 255, 255);
}
.heading_primary_projects {
  color: rgb(0, 0, 0);
}
.heading_primary_main {
  display: block;
  font-size: 1.7rem;
  font-weight: 100;
  font-family: "Gilda Display", serif;
  letter-spacing: 0.4rem;
}

.heading_primary_second {
  font-size: 1rem;
  font-weight: 200;
  text-transform: uppercase;
  backface-visibility: hidden;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.4rem;
}

.heading_primary_main_mobile {
  display: block;
  font-size: 1rem;
  font-weight: 100;
  font-family: "Gilda Display", serif;
  letter-spacing: 0.4rem;
}

.heading_primary_second_mobile {
  font-size: 0.6rem;
  font-weight: 200;
  text-transform: uppercase;
  backface-visibility: hidden;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.4rem;
}

/*BURGER////////////////////////////*/
