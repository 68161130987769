.text_describe {
  padding: 20px;
  text-align: center;
}

.bacara_project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
}

.bacara_project_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text_describe_wrapper {
  max-width: 450px;
  min-width: 250px;
}

.bakara_photos img {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.project_main_header {
  text-decoration: none;
  font-weight: 200;
}

.project_header {
  text-decoration: none;
  font-weight: 200;
}

.bakara_photos_mobile {
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.img_mobile {
  max-width: 600px;
  padding: 5px 0 5px 0;
}
