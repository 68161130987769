.footer_menu_wrapper {
  width: 300px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 12px;
}

.footer_menu_wrapper_mobile {
  width: 200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 30px;
  font-size: 10px;
}
