.popup {
  margin: 160px auto;
  padding: 20px;
  background: rgba(0, 0, 0, 0);
  border-radius: 5px;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
  color: aliceblue;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute; /* 2 */
  left: 50%;
  justify-content: center;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 30px;
  margin-top: 70px;
  margin-left: -12px;
}
.popup .close:hover {
  cursor: pointer;
  color: rgb(128, 128, 128);
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .popup {
    width: 70%;
  }
}

.column_menu {
  display: flex;
  flex-direction: column;
}

a {
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
}

.button,
a {
  display: inline-block;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 30px;
  color: aliceblue;
  padding: 5px;
  text-transform: uppercase;
  outline: none;
  letter-spacing: 5px;
}

.button:hover,
a:hover {
  color: rgba(202, 202, 202, 0.938);
}

p {
  display: block;
}

.menu_contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.burger-menu,
.burger-menu.open {
  width: 60px;
  display: block;
  cursor: pointer;
  /* position: fixed; */
  margin-top: 32px;
  z-index: 9999;
  padding: 10px;
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 2px;
  background-color: rgb(255, 255, 255);
  margin: 5px 0;
  transition: 0.4s;
}

.burger-menu .bar1_projects,
.bar2_projects,
.bar3_projects {
  width: 25px;
  height: 2px;
  background-color: rgb(0, 0, 0);
  margin: 5px 0;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

.burger-menu.open .bar1_projects {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
  background-color: rgb(255, 255, 255);
}

.burger-menu.open .bar2_projects {
  opacity: 0;
}

.burger-menu.open .bar3_projects {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
  background-color: rgb(255, 255, 255);
}

.text_box {
  display: flex;
}

/* Menu */

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0px;
}

.block {
  display: block;
}

/*LOGIN////////////////////////////////////////////*/

@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

.bg_body {
  font-family: "Lato", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
}

form {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
}

form .form_field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  outline: 2px solid rgb(207, 206, 206);
  border-width: 0px;
  box-shadow: none;
}
form input {
  font-family: inherit;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  /* border-width: 1px; */
  display: block;
  padding: 0.3rem;
  font-size: 17px;
  color: #ffffff;
  font-size: 12px;
  outline: none;
  box-shadow: none;
  border: none;
}
form .btn {
  outline: 2px solid rgb(207, 206, 206);
  border: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 auto;
  padding: 0.3rem 2.5rem;
  text-align: center;
  background-color: #25252500;
  color: rgb(207, 206, 206);

  font-size: 17px;
}
