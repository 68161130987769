.wrapper {
  margin-right: auto;
  margin-left: auto;

  max-width: 1380px;

  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.card1Wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  object-fit: cover;
  padding: 5px;
}

.containerCard1 {
  position: relative;
  width: 31%;
  height: auto;
  object-fit: fill;
}

.containerCard1_2 {
  width: 70%;
  max-height: 530px;
  padding-left: 10px;
}

.containerCard2 {
  position: relative;
  width: 70%;
  height: auto;
  object-fit: fill;
  padding-right: 10px;
}

.containerCard2_2 {
  position: relative;

  width: 31%;
  max-height: 530px;
}

.img {
  display: block;
  width: 100%;
  max-height: 530px;
}

.img2 {
  display: block;
  width: 100%;
  max-height: 530px;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #313131;
}

.containerCard1:hover .overlay {
  opacity: 0.9;
}

.containerCard2_2:hover .overlay {
  opacity: 0.9;
}

.text {
  position: absolute;
  display: flex;
  flex-direction: column;
}

.textHeader {
  padding-top: 40px;
  padding-left: 20px;
  font-size: 20px;
  color: aliceblue;
}

.textDescription {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  font-size: 15px;
  color: aliceblue;
}

.link_more {
  padding-top: 20px;
  font-size: 13px;
  letter-spacing: normal;
  padding-left: 20px;
  text-decoration: underline;
}

.wrapper_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  justify-content: center;
  background-color: #fff;
  color: #444;
  padding-top: 40px;
}
.imgStyle {
  width: 350px;
  margin-left: 10px;
  margin-right: 10px;
}
.link_more_mobile {
  padding-top: 10px;
  font-size: 12px;
  letter-spacing: normal;
  padding-left: 0;
  text-decoration: underline;
  color: black;
}
.project_describe {
  margin-left: 20px;
  margin-right: 20px;
  letter-spacing: 1px;
  line-height: 17px;
  font-size: 14px;
}
.project_describe_heading {
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 3px;
}
